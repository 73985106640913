
  /**
   * this file was autogenerated by the icon-renderer module
   * the settings for this are in the nuxt.config.ts file
   * if you would like to override the contents in this file you can create a custom component in the
   * assets/icons directory and name it the same as the icon you would like to override
   * */
  <template>
  <svg width="26" height="50" viewBox="0 0 26 50" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M26 4.5L18.5 8.83013V0.169872L26 4.5ZM0.25 49.5V48.125H1.75V49.5H0.25ZM0.25 45.375V42.625H1.75V45.375H0.25ZM0.25 39.875V37.125H1.75V39.875H0.25ZM0.25 34.375V31.625H1.75V34.375H0.25ZM0.25 28.875V26.125H1.75V28.875H0.25ZM0.25 23.375V20.625H1.75V23.375H0.25ZM0.25 17.875V16.5H1.75V17.875H0.25ZM0.25 16.5C0.25 15.9363 0.286631 15.3807 0.357728 14.8357L1.84513 15.0297C1.7824 15.5106 1.75 16.0013 1.75 16.5H0.25ZM1.21777 11.6193C1.64778 10.5823 2.21049 9.61471 2.88452 8.73759L4.0739 9.65158C3.47883 10.426 2.98246 11.2796 2.60336 12.1938L1.21777 11.6193ZM5.2376 6.38452C6.11471 5.71049 7.08231 5.14778 8.11926 4.71777L8.69384 6.10336C7.77965 6.48246 6.92596 6.97882 6.15159 7.57389L5.2376 6.38452ZM11.3357 3.85773C11.8807 3.78663 12.4363 3.75 13 3.75V5.25C12.5013 5.25 12.0106 5.2824 11.5297 5.34513L11.3357 3.85773ZM13 3.75H14.625V5.25H13V3.75ZM17.875 3.75H21.125V5.25H17.875V3.75Z" fill="url(#paint0_linear_16292_192414)"/>
<defs>
<linearGradient id="paint0_linear_16292_192414" x1="2.04167" y1="27" x2="26" y2="27" gradientUnits="userSpaceOnUse">
<stop stop-color="#5B1FD3"/>
<stop offset="1" stop-color="#44179D"/>
</linearGradient>
</defs>
</svg>

  </template>

  <script>
  export default {
    name: 'ClaimTodoOnholdDashedArrow',
    inheritAttrs: true,
  }
  </script>
